<template>
  <div>
    <div class="container wave-container dark-mode p-5">

      <div class="player-container">
        <div class="waveform" ref="waveform" :style="bgStyle"></div>
      </div>

      <hr>

      <div class="row mb-4">
        <div class="col">
          <div class="mb-2">
            <label>Width</label>
            <input class="form-control" type="number" min="1" v-model="canvasWidth" @change="calcAndDrawWaveform" />
          </div>
          <div>
            <label>Height</label>
            <input class="form-control" type="number" min="1" v-model="canvasHeight" @change="calcAndDrawWaveform" />
          </div>
        </div>
        <div class="col">
          <label>Preset</label>
          <select class="form-control" v-model="selectedFilename" @change="calcAndDrawWaveform">
            <option v-for="f in filenames" :value="f.file">{{ f.name }}</option>
          </select>
        </div>
        <div class="col">
          <label class="btn" for="uploadFile">
            <font-awesome-icon icon="upload"></font-awesome-icon>
            Upload file
          </label>
          <input
            id="uploadFile"
            class="inputfile"
            type="file"
            @change="handleUpload"
          >
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <label>Draw Mode</label>
          <select
            v-model="drawMode"
            class="form-control mb-2"
            @change="calcAndDrawWaveform"
          >
            <option
              v-for="dm in drawModeOptions"
              :value="dm"
            >
              {{ dm }}
            </option>
          </select>

          <label>Interpolation Method</label>
          <select
            v-model="interpolationMethod"
            class="form-control"
            @change="calcAndDrawWaveform"
          >
            <option
              v-for="im in interpolationMethodOptions"
              :value="im"
            >
              {{ im }}
            </option>
          </select>
        </div>

        <div class="col">
          <label>Value Scale</label>
          <select
            v-if="valueScale"
            v-model="valueScale"
            class="form-control"
            @change="calcAndDrawWaveform"
          >
            <option
              v-for="vs in valueScaleOptions"
              :value="vs"
            >
              {{ vs }}
            </option>
          </select>
        </div>

        <div class="col">
          <div class="mb-4">
            <label>Channels</label>
            <select
            v-model="channelMode"
            class="form-control"
            @change="calcAndDrawWaveform"
          >
            <option
              v-for="cm in channelModeOptions"
              :value="cm"
            >
              {{ cm }}
            </option>
          </select>
          </div>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col">
          <div>
            <label>Line Width {{ lineWidth }}</label>
            <input class="form-control" type="range" min="0" max="5" step="0.1" v-model="lineWidth" @change="calcAndDrawWaveform" />
          </div>
        </div>
        <div class="col">
          <div class="form-group form-check mb-4">
            <input class="form-check-input" id="shadowEnabledCheckbox" type="checkbox" v-model="enableShadow" @change="calcAndDrawWaveform" />
            <label class="form-check-label" for="shadowEnabledCheckbox">Shadow</label>
          </div>

          <div>
            <label>Shadow Blur {{ shadowBlur }}</label>
            <input class="form-control" type="range" min="0" max="50" step="1" v-model="shadowBlur" @change="calcAndDrawWaveform" />
          </div>

          <div>
            <label>Shadow X-Offset {{ shadowOffsetX }}</label>
            <input class="form-control" type="range" min="-50" max="50" step="1" v-model="shadowOffsetX" @change="calcAndDrawWaveform" />
          </div>

          <div>
            <label>Shadow Y-Offset {{ shadowOffsetY }}</label>
            <input class="form-control" type="range" min="-50" max="50" step="1" v-model="shadowOffsetY" @change="calcAndDrawWaveform" />
          </div>

        </div>
        <div class="col">
          <div class="mb-4">
            <label>Multisample {{ multiSampleFactor }}</label>
            <input class="form-control" type="range" min="0.1" max="8" step="0.1" v-model="multiSampleFactor" @change="calcAndDrawWaveform" />
          </div>
        </div>
      </div>

      <hr>

      <div class="row mb-4">
        <div class="col">
          <div><label>Stroke Color</label></div>
          <!--<input class="form-control" type="color" v-model="strokeStyle" @change="calcAndDrawWaveform">-->
          <!--<vue-gpickr v-model="strokeStyleGradient" />-->
          <ColorPicker id="strokeStylePicker" :color="strokeStyle" @color-change="updateStrokeStyleDebounced" />
        </div>
        <div class="col">
          <div><label>Shadow Color</label></div>
          <!--<input class="form-control" type="color" v-model="strokeStyle" @change="calcAndDrawWaveform">-->
          <!--<vue-gpickr v-model="strokeStyleGradient" />-->
          <ColorPicker id="shadowColorPicker" :color="shadowColor" @color-change="updateShadowStyleDebounced" />
        </div>
        <div class="col">
          <div><label>Background Color</label></div>
          <!--<input class="form-control" type="color" v-model="strokeStyle" @change="calcAndDrawWaveform">-->
          <!--<vue-gpickr v-model="strokeStyleGradient" />-->
          <ColorPicker id="backgroundStylePicker" :color="backgroundStyle" @color-change="updateBackgroundStyleDebounced" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { ColorPicker } from 'vue-accessible-color-picker'
import { debounce } from 'lodash'

const sinc = (value) => {
  if (value === 0) {
    return 1
  }

  const piTimesValue = Math.PI * value

  return Math.sin(piTimesValue) / piTimesValue

}

export default {
  name: 'WaveformRenderer',
  props: {
    filename: { type: String, required: false, default: 'sine_10_samples.wav' },
  },
  components: { ColorPicker },
  data() {
    return {
      sound: null,
      loading: true,
      waveformData: [],
      buffer: null,
      dataLengthWidthRatio: 1,
      sppBelowOne: false,
      samplePointData: [],

      audioCtx: null,

      canvasWidth: null,
      canvasHeight: null,

      canvasCtx: null,
      canvasEl: null,
      canvasData: null,
      canvasPadding: 10,

      bgStyle: '',
      backgroundStyle: 'rgba(0, 0, 0, 0)',
      filepath: '',

      multiSampleFactor: 1,

      drawMode: 'line',
      drawModeOptions: ['bar', 'line'],

      valueScale: 'linear',
      valueScaleOptions: ['linear', 'log'],

      channelMode: 'stereo',
      channelModeOptions: ['mono', 'stereo'],

      selectedFilename: null,
      filenames: [
        { name: 'Kick', file: 'e_400.wav' },
        { name: 'Sine 10 Samples', file: 'sine_10_samples.wav' },
        { name: 'Single Sine Wave', file: 'sine_single.wav' },
        { name: 'Debug Sine Wave', file: 'sine_debug.wav' },
      ],

      interpolationMethod: 'sinc',
      interpolationMethodOptions: ['sinc', 'linear'],

      lineWidth: 1,
      strokeStyle: '#d2e7ff',
      // strokeStyleGradient: new LinearGradient({ angle: 0, stops: [ ['#000000', 0], ['#ffffff', 1], ] }),
      enableShadow: true,
      shadowColor: 'rgba(0, 0, 0, 0.9)',
      shadowBlur: 20,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
    }
  },
  computed: {
    dpr() {
      return this.multiSampleFactor
    },
  },
  async created() {
    if (this.filename) {
      this.selectedFilename = this.filename
    }

    // this.init()

    this.updateStrokeStyleDebounced = debounce(this.updateStrokeStyle, 250)
    this.updateShadowStyleDebounced = debounce(this.updateShadowStyle, 250)
    this.updateBackgroundStyleDebounced = debounce(this.updateBackgroundStyle, 250)

  },
  watch: {
    selectedFilename() {
      console.log('watch', this.selectedFilename)
      this.init()
    },
  },
  methods: {
    updateStrokeStyle(val) {
      if (this.strokeStyle !== val.cssColor) {
        this.strokeStyle = val.cssColor
        this.setCanvasCtxStyle()
        this.drawWaveform()
      }
    },
    updateShadowStyle(val) {
      if (this.shadowColor !== val.cssColor) {
        this.shadowColor = val.cssColor
        this.setCanvasCtxStyle()
        this.drawWaveform()
      }
    },
    updateBackgroundStyle(val) {
      if (this.backgroundStyle !== val.cssColor) {
        this.backgroundStyle = val.cssColor
        this.setCanvasCtxStyle()
        this.drawWaveform()
      }
    },
    async init() {
      if (!this.audioCtx) {
        this.audioCtx = new AudioContext()
      }

      // load data
      if (this.selectedFilename) {
        this.loadData()

      }

    },
    handleUpload(e) {
      let file = e.target.files[0],
          reader = new FileReader()

      reader.readAsArrayBuffer(file)

      reader.onload = (e) => {
        this.loadData(e.target.result)

      }

    },
    calcAndDrawWaveform(newValue, oldValue) {
      this.calcWaveformData()
      this.drawWaveform()
    },
    async loadData(arrayBuffer) {
      if (this.selectedFilename && !arrayBuffer) {
        const filepath = this.filepath = require('../../public/audio/' + this.selectedFilename)

        const response = await fetch(filepath)

        const reader = response.body.getReader()
        const contentLength = +response.headers.get('Content-Length')

        let receivedLength = 0
        let chunks = []

        while (true) {
          const { done, value } = await reader.read()

          if (done) {
            break
          }

          chunks.push(value)
          receivedLength += value.length
          this.progressWidth = (((receivedLength / contentLength) * 100) || 0) + '%'

        }

        arrayBuffer = new ArrayBuffer(receivedLength)
        let chunksAll = new Uint8Array(arrayBuffer)
        let position = 0
        for (let chunk of chunks) {
          chunksAll.set(chunk, position)
          position += chunk.length
        }

        this.progressWidth = '0%'

      }

      this.audioCtx.decodeAudioData(arrayBuffer).then((buffer) => {
        if (buffer.numberOfChannels < 2) {
          this.channelMode = 'mono'
        }

        this.buffer = buffer
        this.calcWaveformData()
        this.drawWaveform()
      })

    },
    calcWaveformData() {
      const buffer = this.buffer
      let rawData = [buffer.getChannelData(0)]
      let rawDataLength = rawData[0].length

      if (this.channelMode === 'stereo') {
        rawData.push(buffer.getChannelData(1))
      }

      if (!this.canvasWidth) {
        this.canvasWidth = this.$refs.waveform.getBoundingClientRect().width
      } else {
        this.$refs.waveform.style.width = this.canvasWidth + 'px'
      }

      if (!this.canvasHeight) {
        this.canvasHeight = this.$refs.waveform.getBoundingClientRect().height
      } else {
        this.$refs.waveform.style.height = this.canvasHeight + 'px'
      }

      const containerRect = this.$refs.waveform.getBoundingClientRect()

      const blockSize = 1 // i.e. how many pixels is each line

      const dpr = this.dpr

      // samples per pixel
      let sppExact = rawDataLength / (containerRect.width * blockSize * dpr)
      let spp = Math.ceil(sppExact)
      // this.dataLengthWidthRatio = (rawDataLength / (containerRect.width * blockSize * dpr)) - spp
      console.log('sppExact', sppExact)

      // average blocks for each px
      const ppData = {}
      for (let channel = 0; channel < rawData.length; channel++) {
        const channelData = rawData[channel]
        ppData[channel] = []

        if (sppExact >= 1) {
          this.sppBelowOne = false

          for (let i = 0; i < (containerRect.width * blockSize * dpr); i++) {
            let blockStart = Math.round(sppExact * i)

            // dont shoot over end
            if (blockStart + spp > rawDataLength) {
              console.log('shootover checkk', blockStart, rawDataLength, spp)
              spp = rawDataLength - blockStart
            }

            if (this.drawMode === 'bar') {
              let sum = 0
              for (let j = 0; j < spp; j++) {
                sum += Math.abs(channelData[blockStart + j])
              }
              sum /= spp
              ppData[channel].push(sum)

            } else if (this.drawMode === 'line') {
              let min = channelData[blockStart]
              let max = channelData[blockStart]

              for (let j = 0; j < spp; j++) {
                const currentValue = channelData[blockStart + j]
                if (currentValue > max) {
                  max = currentValue
                }

                if (currentValue < min) {
                  min = currentValue
                }
              }

              // console.log([min, max])
              ppData[channel].push([min, max])

            }

          }

        } else {
          this.sppBelowOne = true
          this.samplePointData = []

          for (let i = 0; i < channelData.length; i++) {
            const x = i / sppExact
            const y = channelData[i]

            this.samplePointData.push([x, y])
          }

          for (let i = 0; i < (containerRect.width * blockSize * dpr); i++) {
            let x = sppExact * i

            let sum = 0

            if (this.interpolationMethod === 'linear') { // linear interpolation
              let x0 = Math.floor(sppExact * i)
              let x1 = x0 + 1

              let y0 = channelData[x0]
              let y1 = channelData[x1]

              if (y1 === undefined) {
                sum = y0

              } else {

                // linear interpolation
                sum = ((y0 * (x1 - x)) + (y1 * (x - x0))) / (x1 - x0)
              }

            } else if (this.interpolationMethod === 'sinc') { // sinc interpolation
              for (let j = 0; j < channelData.length; j++) {
                sum += channelData[j] * sinc(x - j)
              }

            } else if (this.interpolationMethod === '') {
              // TODO
            }



            if (this.drawMode === 'bar') {
              ppData[channel].push(sum)

            } else if (this.drawMode === 'line') {
              ppData[channel].push([sum, sum])

            }
          }

        }

      }

      // normalize
      this.waveformData = []
      for (let channel = 0; channel < rawData.length; channel++) {
        if (this.drawMode === 'bar') {
          const maxData = Math.max(...ppData[channel])
          const multiplier = 1 / maxData
          this.waveformData.push(ppData[channel].map(n => n * multiplier))

        } else if (this.drawMode === 'line') {
          const maxData = Math.max(...ppData[channel].map(n => n[1]))
          // const multiplier = 1 / maxData
          const multiplier = 1
          this.waveformData.push(ppData[channel].map((n) => {
            const oMin = n[0] * multiplier
            const oMax = n[1] * multiplier

            return [oMin, oMax]
          }))

        }

      }

      // setup canvas
      if (!this.canvasEl) {
        const canvas = this.canvasEl = document.createElement('canvas')
        canvas.style.width = '100%'
        canvas.style.height = '100%'
        this.$refs.waveform.appendChild(canvas)
        const ctx = this.canvasCtx = canvas.getContext('2d')
      }

      this.canvasEl.width = containerRect.width * dpr
      this.canvasEl.height = (containerRect.height + 2 * this.canvasPadding) * dpr
      // canvas.height = (containerRect.height * dpr) + (2 * this.canvasPadding) ???
      this.canvasCtx.translate(0.5, 0.5 + (this.canvasEl.offsetHeight / 2 + this.canvasPadding) * dpr) // y = 0 is middle
      // this.canvasCtx.scale(1/this.dpr, 1/this.dpr)


      // this.setStrokeStyleFromGradient()

      this.setCanvasCtxStyle()

    },
    logScale(value) {
      const negFactor = value < 0 ? -1 : 1
      value = Math.abs(value)
      const powFactor = 1/10
      return (( Math.pow(powFactor, value) - 1 ) / ( powFactor - 1)) * negFactor

    },
    setCanvasCtxStyle() {
      this.bgStyle = { 'background-color': this.backgroundStyle }

      this.canvasCtx.lineWidth = this.lineWidth * this.dpr
      this.canvasCtx.strokeStyle = this.strokeStyle
      this.canvasCtx.fillStyle = this.strokeStyle
      this.canvasCtx.shadowColor = this.enableShadow ? this.shadowColor : 'rgba(0, 0, 0, 0)'
      this.canvasCtx.shadowBlur = this.shadowBlur
      this.canvasCtx.shadowOffsetX = this.shadowOffsetX
      this.canvasCtx.shadowOffsetY = this.shadowOffsetY

    },
    drawWaveform() {
      this.clear()

      const channelCount = this.waveformData.length

      for (let channel = 0; channel < channelCount; channel++) {
        const channelData = this.waveformData[channel]
        const channelScaleFactor = 1 / channelCount
        const perChannelHalfHeight = this.canvasEl.height / (2 * channelCount)

        const channelOffset = (channelCount - ((channel * 2) + 1)) * perChannelHalfHeight
        const scalingFactor = -1 * ((this.canvasEl.offsetHeight / 2) - this.canvasPadding) * this.dpr * channelScaleFactor

        if (this.drawMode === 'bar') {

          for (let i = 0; i < channelData.length; i++) {
            let value = channelData[i]

            if (this.valueScale === 'log') {
              value = this.logScale(value)
            }

            this.drawBar(i, (value * scalingFactor) + channelOffset)

          }

        } else if (this.drawMode === 'line') {
          this.canvasCtx.beginPath()
          this.canvasCtx.moveTo(0, (this.canvasCtx.height * channelScaleFactor / 2) + channelOffset)

          for (let i = 0; i < channelData.length; i++) {
            let value = channelData[i]

            const x = i // * this.dataLengthWidthRatio

            if (this.valueScale === 'log') {
              value = [this.logScale(value[0]), this.logScale(value[1])]
            }

            // console.log('drawing line', x, value[0], value[1])

            if (value[0] != value[1]) {
              this.canvasCtx.lineTo(x, (value[0] * scalingFactor) + channelOffset)
              this.canvasCtx.lineTo(x, (value[1] * scalingFactor) + channelOffset)

            } else {
              this.canvasCtx.lineTo(x, (value[0] * scalingFactor) + channelOffset)

            }

          }

          this.canvasCtx.stroke()

        }

        // const tmpStrokeStyle = this.canvasCtx.strokeStyle
        // sample dots when having more pixels than samples
        if (this.sppBelowOne) {
          this.samplePointData.forEach((xy) => {
            this.drawCircle(xy[0], (xy[1] * scalingFactor) + channelOffset)
          })
        }

        // this.canvasCtx.strokeStyle = tmpStrokeStyle

      }

    },
    drawBar(x, height) {
      this.canvasCtx.beginPath()
      this.canvasCtx.moveTo(x, -height)
      this.canvasCtx.lineTo(x, height)
      this.canvasCtx.stroke()

    },
    drawCircle(x, y) {
      const radius = 3

      this.canvasCtx.beginPath()
      this.canvasCtx.arc(x, y, radius, 0, 2 * Math.PI)
      this.canvasCtx.fill()
    },
    clear() {
      this.canvasCtx.clearRect(0, -this.canvasEl.height/2, this.canvasEl.width, this.canvasEl.height)
    },
    /* setStrokeStyleFromGradient() {
      const gradient = this.canvasCtx.createLinearGradient(0, 0, this.canvasEl.width, 0)
      this.strokeStyleGradient.stops.forEach((stop) => {
        gradient.addColorStop(stop[1], stop[0])

      })
      this.strokeStyle = gradient
    } */
  }
}

</script>

<style lang="scss">

.player-container {
  height: 30rem;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border-radius: 3px;
  // box-shadow: 2px 2px 2px #000;
  // border: 1px solid rgba(255, 255, 255, 0.1);

  .flex-align-center {
    align-items: center;
  }

  .waveform {
    height: 30rem;
    margin: 0 auto;
    -webkit-user-select: none;
    user-select: none;
  }
}

.vacp-color-picker {
  // color: #000;
  background-color: transparent !important;

  .vacp-color-inputs {
    input, button {
      color: #fff !important;
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  .vacp-copy-button {
    display: none !important;
  }
}

.wave-container {
  background: rgba(0, 0, 0, 0.8);
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.8); */
}
</style>
